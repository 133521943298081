import axios from 'axios';

const instance = axios.create({
    baseURL: window.location.origin
});

function getList() {
    return instance.get('api/SkypeBot');
}

function create(bot) {
    return instance.post('api/SkypeBot', bot);
}

function edit(bot) {
    return instance.put(`api/SkypeBot/${bot.botId}`, bot);
}

function deleteBot(bot) {
    return instance.delete(`api/SkypeBot/${bot.botId}`);
}

function getActivityLogByBotId(botId) {
    return instance.get(`api/SkypeBot/${botId}/activity-logs`);
}

function getActivityLogs() {
    return instance.get(`api/SkypeBot/activity-logs`);
}

function isSkypeAccountInUse(username) {
    return instance.get(`api/SkypeBot/is-skype-account-in-use`, {params: {username: username}});
}


export default {
    getList,
    create,
    edit,
    deleteBot,
    getActivityLogByBotId,
    getActivityLogs,
    isSkypeAccountInUse,
};