<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12" md="6" lg="4">
          <span class="mb-1 small-text">Key</span>
        </b-col>
        <b-col>
          <span class="mb-1 small-text">Value</span>
        </b-col>
      </b-row>
      <b-row v-for="(keyValue, index) in value" :key="index" class="mb-1">
        <b-col cols="12" md="4">
          <b-form-input
            v-model.trim="keyValue.key"
            size="sm"
            debounce="500"
            maxLength="100"
            @change="keyValue.key = _alphanumericWithUnderscore($event.trim())"
            pattern="[a-zA-Z0-9_]+"
            title="alphanumeric or underscore only"
            required
          />
        </b-col>
        <b-col>
          <b-form-input
            v-model.trim="keyValue.value"
            size="sm"
            debounce="500"
            @change="keyValue.value = $event.trim()"
            required
          />
        </b-col>
        <b-col cols="1">
          <b-icon-dash-square
            class="clickable"
            variant="danger"
            @click="value.splice(value.indexOf(keyValue), 1)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span
            class="clickable text-primary"
            style="font-size: 0.85em"
            @click="value.push({ key: '', value: '' })"
            ><b-icon-plus /> Add Key Value</span
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {};
  },
  mounted() {},
  watch: {
    value: {
      handler(newVal) {
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
