<template>
  <div>
    <b-row>
      <b-col>
        <div
            class="btn-edit-skype-group p-2 border rounded"
            @click="openSkypeGroupEditor"
        >
          <small class="d-block" v-for="group in groups" :key="group.name">
            <strong>{{ group.name }}</strong> ( {{ group.affiliateCodes }} )
          </small>
        </div>
      </b-col>
    </b-row>

    <b-modal
        size="xl"
        v-model="skypeGroupModal"
        scrollable
        hide-header
        centered
        button-size="sm"
        @ok.prevent="emitChanges"
        @cancel="formatGroups"
        @shown="adjustHeight"
    >
      <b-row class="mb-3">
        <b-col>
          <small class="pl-3 text-muted"
          >Skype Group Name must be unique. Each group can have multiple
            affiliate codes separated by comma.</small
          >
          <b-button
              class="float-right"
              variant="outline-primary"
              size="sm"
              @click="addSkypeGroup"
          >
            Add Skype Group
          </b-button>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col>
          <b-form ref="mainForm" @submit.prevent="emitChanges">
            <b-card
                no-body
                class="mb-2"
                v-for="(group, index) in groups"
                :key="index"
            >
              <div class="py-2 px-3">
                <b-row>
                  <b-col>
                    <b-row class="mb-2">
                      <b-col xl="2" lg="3" md="6" cols="12">
                        <small>Skype Group Name</small>
                      </b-col>
                      <b-col>
                        <b-form-input v-model="group.name" size="sm" required/>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col xl="2" lg="3" md="6" cols="12">
                        <small>Affiliate Codes</small>
                      </b-col>
                      <b-col>
                        <b-form-textarea
                            ref="textareaAffiliateCodes"
                            v-model="group.affiliateCodes"
                            size="sm"
                            required
                            oninput="this.style.height = ''; this.style.height = (this.scrollHeight + 2) +'px'"
                            @change="
                            (v) => {
                              formatAffiliateGroups(v, group);
                            }
                          "
                        />
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="1">
                    <b-button
                        class="float-right"
                        variant="outline-secondary"
                        size="sm"
                        @click="removeSkypeGroup(group)"
                    >
                      <b-icon-x scale="1.2"/>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-card>
            <small v-if="hasDuplicate" class="text-danger">
              There is duplicated Skype Group Name.
            </small>
          </b-form>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'SkypeGroupHandler',
  props: ['value'],
  data() {
    return {
      skypeGroupModal: false,
      groups: [{name: '', affiliateCodes: []}],

      hasDuplicate: false,
    };
  },
  mounted() {
    this.formatGroups();
  },

  watch: {
    value: {
      handler() {
        this.formatGroups();
      },
      deep: true,
    },
  },
  methods: {
    adjustHeight() {
      const textareas = this.$refs['textareaAffiliateCodes'];
      if(textareas && textareas.length > 0) {
        textareas.forEach(v => {
          const textarea = v.$el;
          textarea.style.height = '';
          textarea.style.height = (textarea.scrollHeight + 2) + 'px'
        })
      }
    },
    openSkypeGroupEditor() {
      this.formatGroups();
      this.skypeGroupModal = true;
    },
    formatGroups() {
      if (this.value && Object.hasOwn(this.value, 'value')) {
        let skypeGroups = this._tryParseJson(this.value.value) || {};
        this.groups = _.map(skypeGroups, (v, key) => {
          let affiliateCodes = _processCommaSeparatedValues(v);
          return {name: key, affiliateCodes: affiliateCodes};
        });
      } else {
        this.groups = [];
      }
    },
    formatAffiliateGroups(v, group) {
      group.affiliateCodes = _processCommaSeparatedValues(v);
    },
    addSkypeGroup() {
      this.groups.push(_defaultSkypeGroup());
    },
    removeSkypeGroup(group) {
      const index = this.groups.findIndex((v) => {
        return _.isEqual(v, group);
      });
      this.groups.splice(index, 1);
    },
    emitChanges() {
      const mainForm = this.$refs['mainForm'];
      if (!mainForm.reportValidity()) {
        return;
      }
      const list = [...this.groups];
      if (_.uniqBy(list, 'name').length !== list.length) {
        this.hasDuplicate = true;
        return;
      } else {
        this.hasDuplicate = false;
      }

      let groups = _.mapValues(_.keyBy(this.groups, 'name'), 'affiliateCodes');
      let skypeGroup = _.isEmpty(groups)
          ? null
          : {
            value: JSON.stringify(groups),
          };
      this.$emit('input', skypeGroup);
      this.skypeGroupModal = false;
    },
  },
};

const _processCommaSeparatedValues = (v) => {
  let csv = v.replace(/[^0-9a-z,]/gi, '').split(',');
  csv = _.map(csv, (v) => {
    return v.trim().substring(0, 100);
  });
  csv = _.uniq(csv);
  csv = csv.filter(Boolean);
  return csv.join(', ');
};

const _defaultSkypeGroup = () => {
  return {
    skypeGroupName: '',
    affiliateCodes: '',
  };
};
</script>

<style scoped>
.btn-edit-skype-group {
  cursor: pointer;
  min-height: 2em;
}

.btn-edit-skype-group:hover {
  color: #888;
  background-color: #ebebeb;
}
</style>
