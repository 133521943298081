<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Skype Bots</h1>
      </b-col>
    </b-row>
    <div>
      <b-row>
        <b-col>
          <b-button class="mb-3" variant="primary" size="sm" @click="showModal(null)">
            <b-icon-plus style="font-size: 1.2em"/>
            Create
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <small class="text-muted">Current Page: {{ currentPage }}. Total rows: {{ rows }}</small>
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" size="sm" align="right" limit="10"
                        v-if="rows > 0"></b-pagination>
          <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table bordered small striped :items="items" :fields="fields" :per-page="perPage"
                   :current-page="currentPage">
            <template v-slot:table-busy>
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(index)="data">
              {{ 1 + data.index + (currentPage - 1) * perPage }}
            </template>
            <template v-slot:cell(botSkypeConfig.skypeUserName)="data">
              {{ data.value || 'old bot' }}
            </template>
            <template v-slot:cell(action)="data">
              <b-icon icon="pencil-square" class="btn-inline-text" title="Bot Settings" @click="showModal(data.item)"/>
              <b-icon icon="trash" class="btn-inline-text ml-1" title="Delete" @click="deleteBot(data.item)"/>
              <!--              <b-icon icon="list-ul" class="btn-inline-text ml-1" title="Show Logs" @click="showLogs(data.item)"/>-->
              <bot-activity-log class="btn-inline-text ml-1" :botId="data.item.botId"/>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </div>

    <b-modal v-model="mainModal" size="xl" ok-title="Submit" :ok-disabled="isCheckingUsername" @ok.prevent="promptConfirm"
             button-size="sm" hide-header centered>
      <b-form ref="mainForm" @submit.stop.prevent="promptConfirm">
        <skype-bot-form v-model="formItem" :skype-account-is-in-use="skypeAccountIsInUse"
                        @isEditingUsername="checkUsername"/>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import skypeBotsServices from "@/services/SkypeBots/SkypeBotsServices";
import SkypeBotForm from "@/components/SkypeBots/SkypeBotForm.vue";
import BotActivityLog from "../../components/SkypeBots/BotActivityLog.vue";

export default {
  name: 'SkypeBots',
  components: {BotActivityLog, SkypeBotForm},
  data() {
    return {
      fields: [
        {key: 'index', label: 'No.', sortable: false,},
        {key: 'botName', label: 'Name', sortable: true,},
        {key: 'botSkypeConfig.skypeUserName', label: 'Skype Username', sortable: false,},
        {key: 'botLanguage', label: 'Language', sortable: true,},
        {key: 'botId', label: 'Bot ID', sortable: true,},
        {key: 'accountId', label: 'Account ID', sortable: true,},
        {key: 'action', label: '', sortable: false,},
      ],
      items: [],
      perPage: 100,
      currentPage: 1,
      isBusy: false,

      mainModal: false,
      formItem: _defaultFormItem(),

      skypeAccountIsInUse: false,
      isCheckingUsername: false,
    }
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  async mounted() {
    await this.getList();
  },
  methods: {
    async getList() {
      try {
        const response = await skypeBotsServices.getList();
        const bots = response.data || [];
        this.items = _.sortBy(bots, ['botName']);
        this.$store.commit('updateSkypeBots', _.map(bots, (b) => {
          return {value: {botId: b.botId, botName: b.botName}, text: b.botName};
        }));
      } catch {
        this._warnError();
      }
    },
    showModal(bot) {
      const formItem = bot ? {...bot} : _defaultFormItem();
      this.skypeAccountIsInUse = false;

      let customValues = [];
      if (formItem.customValues) {
        let customObj = JSON.parse(formItem.customValues);
        if (_.isObject(customObj) && !_.isArray(customObj)) {
          _.forEach(customObj, (value, key) => {
            customValues.push({key: key, value: value});
          });
        }
      }
      formItem.customValues = customValues;

      if (!formItem.botSkypeConfig) {
        formItem.botSkypeConfig = _defaultBotSkypeConfig();
        this.botSkypeConfig = _defaultBotSkypeConfig();
      }

      if (_.some(formItem.customValues, {key: _defaultAffiliateCodeKey,})) {
        formItem.botSkypeConfig.defaultAffiliateCode = _.find(formItem.customValues, {key: _defaultAffiliateCodeKey,}).value;
      } else {
        formItem.customValues.push({key: _defaultAffiliateCodeKey, value: '',});
      }

      if (_.some(formItem.customValues, {key: _invalidAffiliateCodeMessageKey,})) {
        formItem.botSkypeConfig.messageInvalidAffiliateCode = _.find(formItem.customValues, {key: _invalidAffiliateCodeMessageKey,}).value;
      } else {
        formItem.customValues.push({key: _invalidAffiliateCodeMessageKey, value: '',});
      }

      this.formItem = formItem;
      this.mainModal = true;
    },
    promptConfirm() {
      const mainForm = this.$refs['mainForm'];

      if (!mainForm.reportValidity()) {
        return;
      }

      const confirmMessage = this.formItem.botId ? 'Update the bot?' : 'Create the bot?';

      this.$bvModal
          .msgBoxConfirm(confirmMessage, {centered: true})
          .then((confirm) => {
            if (confirm) {
              this.submit();
            } else {
              this.mainModal = true;
            }
          });
    },
    async submit() {
      const formItem = _.cloneDeep(this.formItem);

      //Process Custom Values
      let customObj = _.reduce(
          formItem.customValues,
          (obj, {key, value}) => ({...obj, [key]: value}),
          {}
      );
      formItem.customValues = JSON.stringify(customObj);
      // if (formItem.botType === 2) formItem.botSkypeConfig = this.botSkypeConfig;

      if (formItem.botId) {
        try {
          await skypeBotsServices.edit(formItem);
          await this.$bvModal.msgBoxOk('Bot had been edited successfully. The bot changes should be registered within 1 minute. Please wait 1 minute before doing any changes to the bot. ', {
            okVariant: 'success',
            centered: true
          });
        } catch {
          this._warnError();
        }

      } else {
        try {
          await skypeBotsServices.create(formItem);
          await this.$bvModal.msgBoxOk('Bot had been created successfully. The bot should be usable within 1 minute. Please wait 1 minute before doing any changes to the bot. ' +
              'You can configure the Intents and Webhooks while waiting.', {
            okVariant: 'success',
            centered: true
          });
        } catch {
          this._warnError();
        }
      }
      await this.getList();
      this.mainModal = false;
    },
    async deleteBot(bot) {
      const confirm = await this.$bvModal.msgBoxConfirm(`Delete the Bot?`, {okVariant: 'danger', centered: true});
      if (confirm) {
        try {
          await skypeBotsServices.deleteBot(bot);
          await this.$bvModal.msgBoxOk('Bot had been deleted successfully. The bot should be completely removed within 1 minute.', {
            okVariant: 'success',
            centered: true
          });
          await this.getList();
        } catch {
          this._warnError();
        }
      }
    },
    async checkUsername(username) {
      this.isCheckingUsername = true;
      const resp = await skypeBotsServices.isSkypeAccountInUse(username);
      this.skypeAccountIsInUse = Boolean(resp.data);
      this.isCheckingUsername = false;
    }
  }
}

const _defaultAffiliateCodeKey = 'skype_default_affiliate_code';
const _invalidAffiliateCodeMessageKey = 'affiliate_code_invalid_message';
const _defaultBotSkypeConfig = () => {
  return {
    "skypeUserName": "",
    "skypePassword": "",
    "skypeUserId": "",
    "skypeDisplayName": "",
    "csSkypeUserName": "",
    "brand": "",
    "messageEmptyRequest": "",
    "messageFileDownloaded": "",
    "messageProvideInformation": "",
  }
}
const _defaultFormItem = () => {
  return {
    "botName": "",
    "botLanguage": "English",
    "customValues": "[]",
    "botType": 2,
    "botSkypeConfig": _defaultBotSkypeConfig()
  }
}
</script>


<style scoped>

</style>